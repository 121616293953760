import firebaseService from '../../../services/firebase';
import { IStory } from '../../../types/IStory';

const dbh = firebaseService.firestore();

// A mock function to mimic making an async request for data
// export function fetchCount(amount = 1) {
//   return new Promise<{ data: number }>((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   );
// }

export async function fetchStories() {
  let storiesBucket: IStory[] = [];
  const querySnapshot = await dbh
    .collection('stories')
    .where('status', '==', 'active')
    .where('position', '>', 0)
    .orderBy('position', 'asc')
    .get();
  querySnapshot.forEach((doc) => {
    storiesBucket.push({
      id: doc.id,
      audioUrl: doc.data().audioUrl,
      author: doc.data().author,
      photoCredits: doc.data().photoCredits,
      illustrator: doc.data().illustrator,
      coverThumbUrl: doc.data().coverThumbUrl,
      coverLargeUrl: doc.data().coverLargeUrl,
      date: doc.data().date,
      status: doc.data().status,
      title: doc.data().title,
      subTitle: doc.data().subTitle,
      rating: doc.data().rating,
      position: doc.data().position,
    });
  });
  return { data: storiesBucket };
  // .catch((error) => {
  //   return { error };
  // });
}

export async function fetchStoryText(id: string) {
  const doc = await dbh.collection('story').doc(id).get();
  if (doc.exists) {
    return { data: { text: doc.data()?.text } };
  }
  // .catch((error) => {
  //   return { error };
  // });
}

export async function fetchStoryMeta(id: string) {
  const doc = await dbh.collection('stories').doc(id).get();
  if (doc.exists) {
    return {
      data: {
        id,
        audioUrl: doc.data()?.audioUrl,
        author: doc.data()?.author,
        photoCredits: doc.data()?.photoCredits,
        illustrator: doc.data()?.illustrator,
        coverThumbUrl: doc.data()?.coverThumbUrl,
        coverLargeUrl: doc.data()?.coverLargeUrl,
        date: doc.data()?.date,
        status: doc.data()?.status,
        title: doc.data()?.title,
        subTitle: doc.data()?.subTitle,
        rating: doc.data()?.rating,
        position: doc.data()?.position,
      },
    };
  }
  // .catch((error) => {
  //   return { error };
  // });
}
