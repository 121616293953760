import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { fetchStoryText, fetchStories, fetchStoryMeta } from './storyAPI';
import { IStory } from '../../../types/IStory';

export interface StoryState {
  currentStory: IStory;
  storyText: string;
  stories: IStory[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: StoryState = {
  currentStory: {
    id: '',
    title: '',
    audioUrl: '',
    author: '',
    coverThumbUrl: '',
    coverLargeUrl: '',
    date: 0,
    status: '',
    subTitle: '',
    position: 0,
  },
  storyText: '',
  stories: [],
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const storyTextAsync = createAsyncThunk(
  'story/fetchStoryText',
  async (id: string) => {
    const response = await fetchStoryText(id);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response?.data;
    }
  }
);

export const storyMetaAsync = createAsyncThunk(
  'story/fetchStoryMeta',
  async (id: string) => {
    const response = await fetchStoryMeta(id);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response?.data;
    }
  }
);

export const storiesAsync = createAsyncThunk('story/fetchStories', async () => {
  const response: { data: IStory[] } | { error: string } = await fetchStories();
  // The value we return becomes the `fulfilled` action payload
  if (response?.data) {
    return response?.data;
  }
});

export const storySlice = createSlice({
  name: 'story',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    update: (state, action) => {
      // const respon = storyAsync(action.payload);

      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currentStory = action.payload;
      // state.currentStory = storyAsync(action.payload);
    },

    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(storyTextAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storyTextAsync.fulfilled, (state, action) => {
        if (action.payload?.text) {
          state.status = 'idle';
          state.storyText = action.payload?.text;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(storyMetaAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storyMetaAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = 'idle';
          state.currentStory = action.payload;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(storiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storiesAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = 'idle';
          state.stories = action.payload;
        } else {
          state.status = 'failed';
        }
      });
  },
});

export const { update } = storySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStory = (state: RootState) => state.story;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default storySlice.reducer as Reducer<typeof initialState>;
